.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.custom-home__header {
  background-color: #232f3e;
  padding-bottom: 30px;
}

.custom-home__header-title {
  color: #ffffff;
}
.custom-home__header-title .awsui-text-large h1 {
  padding-bottom: 0;
}

.custom-home__category,
.custom-home__header-title p {
  color: #d5dbdb;
  font-weight: lighter;
}

@media (min-width: 992px) {
  .custom-home__header-sidebar {
    margin-bottom: 1rem;
  }

  .custom-home__sidebar {
    margin-top: -60px;
  }
}
.custom-awsui-util-pt-xxxl {
  padding-top: 40px;
}

.custom-home__header-title > * {
  max-width: 700px;
}

.custom-home-image__placeholder:before {
  content: "X";
  display: block;
  background-color: #f2f3f3;
  color: #ffffff;
  text-align: center;
  font-size: 400px;
  line-height: 400px;
}

.custom-home__external {
  white-space: nowrap;
}

.custom-home__external > :last-child {
  margin-left: 0.5rem;
}

.custom-inner-table-row {
  border-bottom: 1px solid #d5dbdb;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.custom-inner-table-row:first-child {
  padding-top: 0;
}

.custom-inner-table-row:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.custom-inner-table-row-detail {
  float: right;
  color: #879596;
}

.custom-home-main-content-area .custom-home-section-header {
  font-weight: 400;
  font-size: 2.8rem;
  line-height: 4rem;
}