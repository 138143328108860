footer {
    height: 40px;
    padding-left: 20px;
    border-top: ridge;
}

footer span {
    line-height: 40px;
}

.build-version {
    padding-right: 20px;
}
